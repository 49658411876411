/* .table_section {
  height: 400px;
  overflow: auto;
} */
.add_plan_btn {
  display: flex;
  justify-content: end;
}
.plan_col,
.action_icon {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .add_plan_btn {
    margin-top: 10px;
  }
}
.mobile_dropdown {
  position: absolute;
  right: 20px;
  top: 0;
}
.header_contents > p {
  font-size: 14px;
  font-family: im;
}
.members_details p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
}
.departmentlist {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: var(--white);
  border-radius: 10px;
  padding: 10px;
}
.depart_row {
  padding: 10px 0;
  border-bottom: 1px solid #e9e9e9;
}
.depart_row p {
  font-size: 14px;
  margin: 0;
  font-family: ir;
}
