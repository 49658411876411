.task_head {
  background: var(--white);
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.task_links {
  border: 1px solid transparent !important;
  border-radius: 0 !important;
  color: var(--black);
  margin-right: 20px;
  font-size: 14px;
  font-family: im;
  padding: 0;
}
.task_links:hover {
  border-bottom: 1px solid var(--red) !important;
  border-radius: 0 !important;
  color: var(--red);
  background: transparent !important;
}
.task_links.active {
  background: transparent !important;
  color: var(--red) !important;
  border-radius: 0 !important;
}
.task_links.active svg,
.task_links.active svg path {
  fill: var(--red);
}
.task_links:hover svg path {
  fill: var(--red);
}
.task_links svg {
  margin-right: 5px;
}

.filter_field {
  align-items: baseline;
  display: flex;
  justify-content: space-evenly;
}
.filter_top {
  align-items: baseline;
  display: flex;
}
.task_head {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.search_input {
  background-color: #f8f8f8;
  border: none;
  padding: 5px;
  border-radius: 10px;
}
.search_filter_field {
  position: relative;
}
.search_filter_field svg {
  position: absolute;
  top: 8px;
  right: 10px;
  fill: var(--gray);
}
.react-datepicker {
  position: absolute !important;
}
.task_left_side {
  background: var(--white);
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.task_left_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
.task_left_head h1 {
  font-size: 20px;
  font-family: gb;
}
.name_logo {
  display: flex;
}
.name_logo p {
  margin: 0;
  padding-left: 5px;
  font-size: 16px;
  font-family: im;
}
.name_logo img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
}
.notice_number {
  background: var(--red);
  border-radius: 50%;
  width: 23px;
  display: flex;
  align-items: center;
}
.notice_number p {
  margin: 0;
  padding-left: 7px;
  color: var(--white);
  font-size: 14px;
  font-family: ir;
}
.project_name {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid transparent;
}
.project_name.active {
  background: #ffe1e1;
  border-radius: 10px;
  border: 1px solid var(--red);
}
.project_name:hover {
  background: #ffe1e1;
  border-radius: 10px;
  border: 1px solid var(--red);
}
.todo_task {
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 10px 20px;
}
.project_tag {
  background: #ffe1e1;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.project_tag p {
  margin: 0;
  font-size: 14px;
  font-family: ir;
  color: var(--red);
}
.task_detail_card {
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 10px;
  margin-top: 10px;
  transition: 0.5s all ease-in-out;
  background: var(--white);
  border: 1px solid var(--white);
}

.task_detail_card:hover {
  cursor: pointer;
  border: 1px solid var(--green);
  transform: scale(1.03);
}

.card_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 0;
  cursor: pointer;
}
.card_head > p {
  color: #949494;
  font-size: 14px;
  font-family: ir;
  margin: 0;
}
.card_head > p > span {
  color: var(--green);
}
.comment_icon {
  background: #ffe380;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  margin-right: 10px;
  gap: 10px;
}
.discuss_point {
  background: #ffe380;
  margin-right: 20px;
}
.attach_point {
  background: #aeffdf;
}
.attach_point,
.discuss_point {
  border-radius: 50%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
.model_tabs_align {
  align-items: center;
}
.discuss_point p,
.attach_point p {
  margin: 0;
  font-size: 10px;
  font-family: ir;
  color: black;
}
.attach_icon {
  background: #aeffdf;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  gap: 10px;
}
.comment_attach {
  display: flex;
}
.periority {
  display: flex;
  align-items: center;
  /* margin-right: 20px; */
}
.periority_time {
  display: flex;
}
.periority_time p,
.periority p {
  margin: 0 !important;
  padding-left: 10px;
  font-size: 14px;
  font-family: ir;
}
.periority_time > p {
  color: #949494;
}
.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}
.card_body h3 {
  font-size: 16px;
  font-family: ib;
  padding: 20px 0;
}
/* .modal_tabs_content  {
  padding: 0 30px;
} */
/* .dashboard_main .modal-content {
  padding: 0 40px !important;
  padding-bottom: 40px !important;
} */
.modal_tabs_btn .task_links {
  padding-bottom: 5px;
}
.detail_points p {
  font-size: 14px;
  font-family: im;
  margin-bottom: 25px;
}
.detail_points_data p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
  margin-bottom: 25px;
}
.detail_points_data .periority,
.assigned_by {
  margin-bottom: 12px;
}
.assigned_by img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.assigned_by {
  display: flex;
  align-items: center;
}
.assigned_by p {
  margin: 0;
}
.detail_tabs_main {
  padding: 20px 0;
  padding-right: 20px;
}
.detail_discription h6 {
  font-size: 16px;
  font-family: im;
  margin-bottom: 20px;
}

.detail_discription p,
.complete_task p,
.activity_heading p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
}

.detail_discription p,
.complete_task p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
}

.complete_task {
  margin-top: 20px;
  background: var(--white);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.complete_task h5 {
  font-size: 16px;
  font-family: ib;
}

.submit_file {
  background: var(--green);
  color: var(--white);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
  width: 100%;
  margin-top: 20px;
}
.submit_file:hover {
  background: var(--white);
  color: var(--green);
  border: 1px solid var(--green);
}
.guZdik {
  min-width: 100% !important;
  border-color: #d1d1d1 !important;
  /* max-width: 100% !important; */
}
.attach_file svg path {
  fill: #d1d1d1 !important;
}
.selected-file {
  color: red;
}
.model_right {
  padding-left: 10px;
  border-left: 1px solid #dee2e6;
}
.comment_field {
  background: #f3f3f3;
  border-color: #f3f3f3;
}
.send_input {
  position: relative;
}

.send_svg {
  position: absolute;
  top: 4px;
  right: 0;
}

.send_coment {
  background: transparent;
  border: none;
}
.file_icon {
  background: #efefef;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.comment_contents {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 10px;
}
.add_comment {
  padding-right: 30px;
}
.comment_img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--red);
}

.comment_img h6 {
  font-size: 14px;
  font-family: ib;
  margin: 0;
}

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.comment_img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.date_time {
  display: flex;
}

.date_time p {
  margin: 0;
  font-size: 14px;
}

.message_text p {
  margin: 0;
}
.comment_mes {
  height: 250px;
  overflow-y: auto;
  padding: 10px;
}
.discussion_main {
  padding-top: 20px;
}
.add_comment h6 {
  font-size: 18px;
  font-family: ib;
}
.name_date h6 {
  font-size: 16px;
  font-family: ib;
}
.photo_header,
.document_header,
.link_header {
  border-radius: 10px;
  padding: 10px 0 0 20px;
  display: flex;
  align-items: center;
}
.link_header {
  background: #d2e4ff;
  margin: 20px 0;
}
.photo_header {
  background: #ffe1e1;
}
.document_header {
  background: #e2fff4;
  margin: 20px 0;
}
.att_img img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.att_img {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
.pdf {
  background: #ffe1e1;
  color: var(--red);
  border-radius: 10px;
  padding: 20px;
}
.pdf p,
.file_download p {
  margin: 0;
  font-size: 16px;
  font-family: ib;
}
.link_header p {
  font-size: 14px;
  font-family: im;
}
.download_doc {
  display: flex;
  border-radius: 10px;
  border: 1px solid #cecece;
  padding: 10px;
}
.file_download {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-left: 1px solid #cecece;
  margin-left: 10px;
}
.clr_red {
  color: var(--red);
}
.link_list ul li a {
  font-size: 16px;
  font-family: ir;
}
.link_list ul li {
  margin-bottom: 10px;
}
.photos_part {
  /* margin-right: 20px; */
  padding-right: 20px;
}
.comeents_icons {
  display: contents;
}
.date_filter {
  display: flex;
  align-items: baseline;
}
@media screen and (max-width: 1199px) {
  .photos_part {
    width: 100%;
  }
}
@media screen and (max-width: 1066px) {
  .filter_by {
    padding-left: 10px;
  }
}
@media screen and (max-width: 991px) {
  .date_report {
    width: 100% !important;
  }
  .comment_contents {
    display: block;
  }

  .activity-container {
    height: 100%;
  }

  .comeents_icons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .comment_mes {
    height: 200px;
  }
  .comment_img {
    display: block;
  }
}
@media screen and (max-width: 375px) {
  .task_head {
    display: block;
  }
  .filter_field {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .date_report {
    width: 50% !important;
  }
  .todo_task {
    margin-top: 20px;
  }
}
@media screen and (max-width: 555px) {
  .comment_mes {
    padding: 0 20px;
  }
  .filter_field {
    display: block;
  }
  .modal-body .filter_field {
    display: flex;
  }
  .search_input {
    width: 100%;
  }
  .date_filter {
    justify-content: center;
  }
  .tsk_tabs .nav-tabs {
    justify-content: center;
  }
}
@media screen and (max-width: 477px) {
  .from_date .form-control {
    padding: 0;
  }
  .date_report {
    width: 60% !important;
  }
  .card_footer {
    display: block;
  }
  .periority_time {
    margin-top: 10px;
    justify-content: center;
  }
  .comment_attach {
    justify-content: center;
  }
}
@media screen and (max-width: 365px) {
  .div_btn {
    display: block !important;
  }
  .toggle_project[aria-expanded="true"] ~ .add_task {
    margin-top: 10px;
    padding: 10px;
  }
  .modal_body {
    padding: 0 !important;
  }
  .card_head {
    display: block;
  }
  .project_tag {
    justify-content: center;
  }
  .card_head > p {
    text-align: center;
    margin-top: 10px;
  }
}
@media screen and (max-width: 321px) {
  .task_links {
    margin-right: 15px;
  }
}

.model_heading p {
  margin: 0;
  color: var(--gray);
  font-size: 14px;
  font-family: ir;
}
.model_heading h1 {
  color: var(--red);
}

.checkbox_wrapper input[type="radio"] {
  display: none;
  visibility: hidden;
}

.checkbox_wrapper label {
  position: relative;
  padding-left: 70px;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  padding: 15px 0 15px 70px;
  border-radius: 10px;
  border: 1px solid #949494;
}

.checkbox_wrapper label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 19px !important ;
  left: 15px;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid var(--green);
  border-radius: 50%;
  z-index: 0;
}

.checkbox_wrapper input[type="radio"]:checked + label {
  padding-left: 70px;
  color: var(--white);
  background: var(--green);
  border: 1px solid var(--green);
}
.checkbox_wrapper input[type="radio"]:checked + label:before {
  background: var(--white);
  border-color: var(--white);
  border-radius: 50%;
}
.checkbox_wrapper label,
.checkbox_wrapper label::before {
  transition: 0.25s all ease;
}
.check_bok {
  margin: 10px 0;
}
.assign_radio {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 5px 0;
}
.date_col {
  margin-bottom: 50px;
}
.from_date {
  position: absolute;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
}
.from_date input {
  border: none;
}
input:focus-visible {
  outline: none !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.date_report {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.fa {
  font-family: "Font Awesome 5 Free", Open Sans;
  font-weight: 501;
}
.badge-warning {
  background: red;
}
.error_div {
  display: flex;
}

.custom-file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background-color: var(--gray);
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.custom-file-input:hover {
  background-color: gray;
}

.task-list-container {
  height: 580px;
  overflow: auto;
  &::-webkit-scrollbar {
    border-radius: 10px;
    background-color: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.selected-file-container {
  margin: 10px 0px;
  background-color: var(--red);
  border-radius: 20px;
  width: fit-content; /* Set width based on content */
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
}

.selected-file-container p {
  color: var(--white);
  text-align: center;
  margin: 0;
  cursor: pointer;
}
.no_color {
  box-shadow: none;
  background: none;
}
.btn_card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
  border-radius: 20px;
  background: var(--white);
}
.toggle_project {
  border: none;
  background: none;
  display: flex;
  align-items: center;
}
.toggle_project > p {
  margin: 0;
  padding-left: 20px;
  font-size: 16px;
  font-family: im;
}
.toggle_project[aria-expanded="true"] > p {
  color: var(--red);
}

.toggle_project.collapsed > p {
  color: var(--black);
}
.card_body {
  padding: 5px 10px;
}
.tasktable_head {
  /* background: var(--black); */
  padding: 10px 20px;
  border-bottom: 1px solid #e9e9e9;
  /* border-radius: 10px; */
}
.tasktable_data {
  padding: 10px 20px;
  border-bottom: 1px solid #e9e9e9;
}
.tasktable_head p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
  /* color: var(--white); */
}
.tasktable_data h6 {
  font-size: 14px;
  font-family: im;
  margin: 0;
  cursor: pointer;
}
.edit_options {
  margin-left: 10px;
  background: #e9e9e9;
  padding: 0px 5px;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.tasktable_data:hover .edit_options {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.add_task {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  padding: 0 5px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.toggle_project[aria-expanded="true"] ~ .add_task {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.div_btn {
  display: flex;
  align-items: baseline;
}
.add_task > p {
  margin: 0;
  font-size: 14px;
  font-family: ir;
  padding-left: 5px;
}
.todo_task_card {
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  border-top: 2px solid var(--gray);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 50px;
  width: 300px;
}

.progress_task_card {
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  border-top: 2px solid var(--red);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 50px;
}

.complete_task_card {
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  border-top: 2px solid var(--green);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 50px;
}

.todo_task_card > p,
.progress_task_card > p,
.complete_task_card > p {
  font-size: 16px;
  font-family: im;
  margin: 0;
}
/* ---------------- */

.card {
  width: 100%;
  padding: 5px 15px;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  margin-bottom: 15px;
  cursor: grabbing;
  border-radius: 10px;
}
.days {
  display: flex;
  justify-content: space-between;
}

.days > p {
  font-size: 12px;
  font-family: im;
  margin: 0;
  color: var(--green);
}

.time {
  display: flex;
  gap: 5px;
}

.time > p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
  cursor: pointer;
}

.dates {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.priority {
  display: flex;
  margin-top: 5px;
  align-items: center;

  i {
    font-size: 12px;
  }
}

.dates > p {
  font-size: 12px;
  font-family: ir;
  margin: 0;
  color: var(--red);
}
.priority > p {
  font-size: 12px;
  font-family: ir;
  margin: 0;
  color: var(--black);
}
.drag_column {
  width: 100%;
  float: left;
  display: flex;
}
.drag_row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.small-box {
  margin-top: 20px;
  height: 250px;
  overflow-x: auto;
  width: 300px;
}
.small-box-add {
  margin-top: 20px;
}

.task {
  border: 1px solid white;
  margin: 10px;
  box-shadow: 1px 3px 10px gray;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid_task {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #e9e9e9;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
    color: var(--white);
  }

  p {
    margin: 0;
    font-size: 14px;
    font-family: ir;
    padding-left: 5px;
    cursor: pointer;
  }
}
.todo_task_head {
  display: flex;
}
.todo_task_head > p {
  font-size: 16px;
  font-family: im;
  margin: 0;
}

/* .cancel_btn {
  background: var(--red);
  font-size: 14px;
  font-family: ir;
  color: var(--white);
  border: 1px solid var(--red);

  &:hover {
    background: var(--white);
    border: 1px solid var(--red);
    color: var(--red);
  }
} */

.save_btn {
  background: var(--green);
  font-size: 14px;
  font-family: ir;
  color: var(--white);
  border: none;
  width: 100%;
  border-radius: 6px !important;
}
.save_btn:hover {
  background: var(--white);
  border: 1px solid var(--green);
  color: var(--green);
}
.border_div {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.member_list ul,
.status_list ul {
  list-style: none;
  padding: 0;
}
.member_list ul li,
.status_list ul li {
  padding: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.edit-delet {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.edit-delet i {
  color: var(--white) !important;
}
.status_list ul li:hover .edit-delet {
  opacity: 1;
}
.member_list ul li:hover,
.status_list ul li:hover {
  background: var(--red);
  color: var(--white);
}
.selected_class {
  background-color: var(--green);
  color: var(--white);
}

.select_col {
  display: flex;
  align-items: center;
}
/* .member_name {
  font-size: 14px;
  font-family: ir;
  margin-bottom: 0;
  margin-right: 5px;
  background: #e9e9e9;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.cross_icon {
  position: absolute;
  top: -11px;
}
.other_number {
  display: flex;
}
.other_number p {
  font-size: 14px;
  font-family: ir;
  margin-bottom: 0;
  margin-right: 5px;
  background: #e9e9e9;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fa-icon-container {
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.dropdown-btn {
  padding: 5px 20px;
  border: none;
  background-color: var(--red);
  border-radius: 5px;

  &:hover {
    background-color: var(--green);
  }
}

.dropdown-btn-dots {
  padding: 0px 5px;
  border: none;
  background-color: var(--white);

  &:hover {
    background-color: var(--white);
  }
}
.user-icon {
  cursor: pointer;
}
.card_right .fa-icon-container {
  display: none;
  transition: all 0.5s ease-in-out;
}

.card_right:hover .fa-icon-container {
  display: flex;
  transition: all 0.5s ease-in-out;
}

.card_right .days .user-icon {
  display: none;
  transition: all 0.5s ease-in-out;
}

.card_right:hover .days .user-icon {
  display: inline-block;
  cursor: pointer;
  margin-top: -5px;
  transition: all 0.5s ease-in-out;
}

.fa-user-plus {
  font-size: 12px;
  color: var(--red);
}

.user-avatar {
  background-color: #ddd;
  color: var(--black);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.user-info {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.user-avatar .cross_icon-board {
  display: none;
}
.cross_icon {
  display: none;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.select_col:hover .cross_icon {
  display: block;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.user-info:hover .cross_icon-board {
  display: inline-block;
  position: absolute;
  top: -3px;
  right: -5px;
  transition: all 0.5s ease-in-out;
}

.fa-circle-xmark {
  font-size: 12px;
}
.margen_set {
  margin: 0 5px;
}
.padding_cls {
  padding-left: 5px;
}
.plus_icon > i {
  background: var(--white);
  border: 1px solid var(--black);
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 2px 1px 2px;
  cursor: pointer;
}
.down_btn > button,
.down_btn > button:focus-visible,
.btn.show:focus-visible,
.btn.show:focus-visible,
.down_btn > button:hover {
  background: none !important;
  border: none !important;
  border-radius: 50%;
  box-shadow: none !important;
  color: var(--black);
}
.btn:first-child:active {
  background: var(--white);
  color: var(--black);
  border-color: var(--black);
}
.padd_class {
  padding: 0;
}
.manin_table_data {
  height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0;
}
@media screen and (max-width: 860px) {
  .edit_options {
    position: unset;
    margin-left: 10px;
  }
  .padd_class {
    padding: 0 10px;
  }
  .scroll_class {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .width_overflow {
    margin: 10px;
    width: 688px;
  }
}

.activity-container {
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* height: 560px; */
}

.activity_heading {
  background-color: lightgray;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px 10px 0px 0px;

  h6 {
    font-size: 16px;
    font-family: im;
    margin: 0;
  }
}

.checklist-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    color: var(--red);
  }

  i {
    margin-left: 10px;
  }
}

.checklist-item input[type="radio"] {
  margin-right: 8px;
}

.checklist-item.completed {
  span {
    color: var(--green);
  }

  i {
    margin-left: 10px;
  }
}

.fa-pencil {
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  color: var(--green);
}

.fa-times {
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: var(--green);
}

.fa-trash {
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  font-size: 13px;
  color: var(--red);
}

.label-name {
  font-size: 14px;
}

.file-preview-row {
  display: flex;
  flex-wrap: wrap;
}

.attachments-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.file-preview-div {
  margin-top: 10px;
  width: 200px;
  border-radius: 20px;
  border: 1px solid var(--gray);
}

.file-preview-icon {
  width: 50px;
  height: 50px;
}

.file-preview-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.attachment-info-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;

  .attachment_name {
    cursor: pointer;
    text-decoration: underline;
  }

  .attachment-name,
  .attachment-time,
  .user-avatar-attachments {
    margin: 0;
    color: var(--black);
    font-size: 12px;
    font-weight: 400;
  }
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid lightgray;
}

.remove-icon {
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--red);
  padding: 5px;
  font-size: 10px;
  border-radius: 50%;
}

.image-container .remove-icon {
  display: none;
}

.image-container:hover .remove-icon {
  display: block;
  transition: all 0.2s ease-in-out;
}

.request_btn_container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.timer_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding-right: 10px;
}

.task_timer {
  height: 50px;
  display: flex;
  align-items: center;
}

.task_timer h1 {
  font-size: 20px;
  font-family: ib;
  margin: 0;
  text-align: center;
}

.add_status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: var(--red);
  color: var(--white);
  height: 50px;
  border: 1px solid var(--red);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: var(--white);
    color: var(--red);
    border: 1px solid var(--red);
  }

  i {
    text-align: center;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-family: ir;
    padding-left: 5px;
    cursor: pointer;
    text-align: center;
  }
}

.add_status_field {
  width: 300px;
  height: 50px;
}

.btn_card_board {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
  border-radius: 20px;
  background: var(--white);
}

.card-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 1000px;
  overflow: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    border-radius: 10px;
    background-color: var(--red);
    height: 5px;
  }

  &::-webkit-scrollbar:hover {
    background-color: var(--green);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--green);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--red);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.btn_status {
  display: flex;
  width: 150px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.add_status_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.task_date > h6 {
  font-size: 14px;
  font-family: ir;
}

.red_high {
  color: var(--red);
  padding-right: 10px;
}
.green_low {
  padding-right: 10px;
  color: var(--green);
}
.gray_medium {
  padding-right: 10px;
  color: var(--gray);
}

.btn_divider {
  padding: 0 20px 20px 20px;
}

.desc-container {
  display: flex;
  gap: 10px;
}
.status_div {
  margin-right: 5px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.add_status_icon {
  border-radius: 5px;
  padding: 5px 10px;
  background: var(--red);
  color: var(--white);
  cursor: pointer;
}
.color_picker {
  border-radius: 10px;
  padding: 5px 20px;
  background: var(--red);
  color: var(--white);
  cursor: pointer;
  border: none;
}
.status_modal {
  height: 350px;
  overflow: auto;
}
.save_color {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  background: var(--red);
  color: var(--white);
  border-radius: 5px;
  padding: 2px 10px;
}
.green_clr {
  color: var(--green);
}

.user-information {
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--red);
  }
}

.drop-zone {
  border-color: #ddd;
  border-style: dashed;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f5f5f5;
}

.comment_container {
  border-radius: 10px;
  padding: 10px;
  /* border: 1px solid lightgray; */
  margin-top: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  p {
    font-size: 14px;
    margin: 0;
  }
}

.edit-reply-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-btn {
  color: var(--green);
  font-size: 14px;
  margin: 0;
  font-family: ib;
  cursor: pointer;
}

.reply-btn {
  color: var(--red);
  font-size: 14px;
  margin: 0;
  font-family: ib;
  cursor: pointer;
}

.reply-container {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .heading-container {
    display: flex;
    gap: 10px;

    i {
      cursor: pointer;
    }

    h6 {
      font-size: 16px;
      font-family: ib;
    }
  }
}

.replies {
  display: flex;
  margin-top: 10px;

  .reply-text {
    font-size: 16px;
    margin: 0;
    font-family: ib;
    color: var(--green);
  }
}

.attachment-container {
  display: flex;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  border: 1px solid var(--red);
  margin: 10px 0px 10px 0px;
  align-items: center;

  .file-name {
    font-size: 14px;
    color: var(--black);
    text-decoration: underline;
    color: var(--green);
    cursor: pointer;
  }
}

.file-preview-icon-comment {
  width: 35px;
  height: 35px;
}
.cross_hiden .rs-input-group-addon {
  display: none !important;
}
.status_main_modal .modal-content {
  width: 450px;
}
@media screen and (max-width: 630px) {
  .status_main_modal .modal-content {
    width: 100%;
  }
}
.active_log {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 10px 0 25px;
}

.active_date {
  text-align: end;
  color: #949494;
}

.active_log li {
  font-size: 14px;
  font-family: ir;
}
.active_log p {
  margin: 0;
  font-size: 12px;
  font-family: ir;
  padding-left: 10px;
}
.activities {
  height: 200px;
  overflow: auto;
  margin-right: 10px;
}
.main_comment_section {
  background: var(--white);
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.high_priority {
  background: var(--red);
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.medium_priority {
  background: var(--gray);
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.low_priority {
  background: var(--green);
  width: 16px;
  height: 16px;
  border-radius: 50px;
}

.add_project_link {
  color: var(--black);
  text-decoration: underline;
}
.add_project_link:hover {
  color: var(--black);
}
.spin_pedding {
  padding: 10px 20px;
}
