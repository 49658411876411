.board_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.tabs_part {
  margin-top: 20px;
}
.main_image p {
  font-size: 16px;
  font-family: ir;
  color: var(--gray);
}
.tabs_btn_part {
  background: var(--white);
  border-radius: 10px;
  padding: 15px 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.tabs_btn {
  border-radius: 10px;
  border: 1px solid transparent;
  background: var(--white);
  color: var(--black);
  text-align: start;
  padding: 5px 10px;
  transition: all 0.3s ease;
  margin: 5px 0;
  font-size: 16px;
  font-family: im;
}
.tabs_btn.active,
.tabs_btn:hover {
  border-radius: 10px;
  border: 1px solid var(--red);
  background: #ffe1e1;
  color: var(--red);
}
.list_heading {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.list_heading h1 {
  font-size: 20px;
  font-family: ib;
}
.table_head p {
  margin: 0;
  font-family: im;
  font-size: 14px;
}
.table_head {
  padding: 10px 10px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 10px;
  /* display: flex;
  justify-content: space-around; */
}
.members_details {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: var(--white);
  align-items: center;
  border-radius: 10px;
  padding: 10px;
}
.email p,
.level p,
.tick_mark p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
}
.tick_mark p {
  padding-left: 10px;
}
.image_name > p,
.depart_name > p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
}
.image_name,
.tick_mark {
  display: flex;
  align-items: center;
}
/* .image_name p{
    padding-left: 5px;
} */
.image_name img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin-right: 5px;
}
.select_border {
  border: none !important;
  /* padding: 0.375rem 0 0.375rem 0.75rem;
  width: 110%; */
}
option {
  font-size: 14px;
  font-family: ir;
}
.detail_row {
  align-items: center;
}
.new_member {
  background: var(--red);
  color: var(--white);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
}
.new_member:hover {
  background: var(--white);
  border: 1px solid var(--red);
  color: var(--black);
}
@media screen and (max-width: 1173px) {
  .tick_mark p {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .email_head {
    display: none;
  }
}
@media screen and (max-width: 757px) {
  .board_header {
    display: block;
  }
}
@media screen and (max-width: 555px) {
  .main_image p {
    font-size: 14px;
  }
  .table_head p {
    text-align: center;
  }
  .image_name {
    display: block;
    text-align: center;
  }
  .col-lg-2,
  .col-lg-3,
  .col-lg-1 {
    padding: 0;
  }
}
.tick_marked > svg {
  margin-right: 10px;
}
.levels > .select_border {
  padding-left: 0;
}
.action_icons {
  display: flex;
  justify-content: space-between;
}
.date_filter {
  display: flex;
  align-items: baseline;
}
.filter_by {
  align-items: baseline;
  display: flex;
}
.filter_select {
  width: auto;
  background-color: #f8f8f8;
  border: none;
}
.filter_by label {
  margin-right: 10px;
}
.mobile_view_insight {
  display: none;
}
.insight_right_main {
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
}
@media screen and (max-width: 575px) {
  .mobile_view_insight {
    display: block;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 0;
  }
  .insight_right_main {
    margin-top: 20px;
  }
  .table_head,
  .members_details {
    display: none;
  }
}
@media screen and (max-width: 757px) {
  .add_new_member {
    margin-bottom: 10px;
  }
  .date_filter {
    margin-top: 10px;
  }
}
.select-dropdown {
  width: 200px !important;
}
._task_name > p {
  cursor: pointer;
  text-decoration: underline;
}
.memberlist {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: var(--white);
  border-radius: 10px;
  padding: 10px;
}
.list_height {
  height: 320px;
  overflow-x: hidden;
}
