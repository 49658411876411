.select_option {
  width: auto;
  background: var(--black);
  color: var(--white);
  background: var(--black)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.dropdown_main {
  display: flex;
  justify-content: flex-end;
}
.dropdown_main {
  margin: 10px 0;
}
