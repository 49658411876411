.header_contents {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px;
}
.header_contents p,
.table_history p {
  font-size: 14px;
  font-family: ir;
  margin: 0;
}
.plan_history_content {
  height: 300px;
  overflow: auto;
  margin-top: 10px;
}
.table_history {
  padding: 10px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 10px;
}

@media screen and (max-width: 466px) {
  .table_history p,
  .header_contents p {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .history_date {
    font-size: 10px !important;
  }
}
