body {
  background: var(--bcolor);
}

.logo h1 {
  font-family: gb;
  font-size: 22px;
  color: var(--black);
}
.department_name {
  text-align: center;
  font-size: 20px;
  font-family: ib;
  color: var(--red);
}
.heading_ {
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  font-size: 30px;
  font-family: ib;
  background-image: linear-gradient(to right, #00a8ff, #38ce94);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.signup_card {
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--green);
  padding: 20px 80px;
}
.signup_card h3 {
  font-size: 20px;
  font-family: ib;
}
.google_icon,
.face_icon {
  border: 1px solid var(--green);
  border-radius: 10px;
  padding: 10px 45px;
}
.line > img {
  max-width: 100%;
}
.pass_icon {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 40px;
}
.pass_icon i {
  color: #cccccc;
}
label {
  font-family: im;
  font-size: 14px;
}
.signup_btn {
  background: var(--green);
  color: var(--white);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
}
.signup_btn:hover {
  background: var(--white);
  border: 1px solid var(--green);
  color: var(--black);
}
.terms {
  color: var(--gray);
  margin: 10px 0;
  font-family: ir;
  font-size: 12px;
}
.form-check-label {
  color: var(--gray);
  font-size: 12px;
  font-family: ir;
}
.forget {
  font-size: 12px;
  font-family: ir;
}
.forget a {
  color: var(--red);
}
.terms span {
  color: var(--red);
}
.remember_forget {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}
.form-check-input {
  border-color: var(--red);
}
.form-check-input:checked {
  background-color: var(--red);
  border-color: var(--red);
}
.form-check-input:focus {
  box-shadow: none;
  border-color: var(--red);
}
.img_col {
  background: #e2fff4;
  border-radius: 10px 0 0 10px;
}
.img_col > img {
  width: 100%;
}
.image_picker {
  padding-top: 37px;
}
.image_picker input {
  display: none;
}
.image_picker img {
  border: 1px solid var(--red);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.text_box {
  resize: none;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  padding: 10px;
}
.form-select:focus {
  border-color: var(--green);
  box-shadow: none;
}
textarea:focus {
  border-color: var(--green);
}
.fields_area {
  padding: 40px 0px;
}
.text_color {
  color: var(--red);
}
.phone_number_input::-webkit-inner-spin-button,
.phone_number_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 1199px) {
  .signup_card {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 991px) {
  .line > img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .img_col {
    display: none;
  }
}
@media screen and (max-width: 477px) {
  .signup_card {
    padding: 20px;
  }
  .fields_area {
    padding: 20px;
  }
  .col-3 {
    padding: 0;
  }
}
@media screen and (max-width: 433px) {
  .google_icon,
  .face_icon {
    padding: 10px 35px;
  }
}
@media screen and (max-width: 393px) {
  .google_icon,
  .face_icon {
    padding: 10px 15px;
  }
}
.was-validated .form-control:valid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
