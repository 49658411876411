.circle {
  width: 10px;
  height: 10px;
  line-height: 25px;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: var(--gray);
  text-align: center;
}
.stepWrapper {
  background: #f9f9f9;
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  position: absolute;
  justify-content: center;
  z-index: 1;
  align-items: center;
}
.stepBlock {
  text-align: center;
}
.stepBlock span {
  color: var(--green);
  line-height: 3;
  font-size: 14px;
  font-family: ir;
}

.stepBlock .circleWrapper {
  padding: 0px 120px;
  position: relative;
}
.selected .circle {
  /* color: black; */
  width: 15px;
  height: 15px;
  background: var(--green);
  border-color: var(--green);
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background: var(--green);
  margin: auto;
  z-index: -1;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  height: 2px;
}

.stepBlock:not(:last-child).selected > .circleWrapper::after,
.stepBlock:not(:last-child).selected
  ~ .stepBlock:not(:last-child)
  > .circleWrapper::after {
  height: 2px;
  opacity: 0.6;
}

.pickerr {
  display: flex;
  justify-content: center;
}
.step_row {
  align-items: center;
}
.modal-footer {
  justify-content: space-between;
}
.back_btn {
  background: var(--white);
  color: var(--green);
  border: 1px solid var(--green) !important;
  padding: 10px 50px;
  margin-right: 10px;
}
.back_btn:hover {
  background: var(--green);
  color: var(--white);
}
.member_images img {
  width: 43px;
  height: 43px;
  border-radius: 50px;
}
.imagies1 {
  position: absolute;
  left: 40px;
}
.imagies2 {
  position: absolute;
  left: 60px;
}
.imagies3 {
  position: absolute;
  left: 80px;
}
/* .member_name {
  border: 1px solid #d1d1d1;
  display: flex;
  padding: 10px;
  border-radius: 10px;
}
.member_name p {
  background: #eff4f9;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 0;
  margin-right: 10px;
} */
.team_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}
.content_team h3 {
  font-size: 18px;
  font-family: ib;
  color: var(--red);
}
.content_team p,
.list_heading p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
  margin: 0;
}
@media screen and (max-width: 575px) {
  .height_set.collapse {
    height: auto;
  }
  .table_head,
  .members_details {
    display: none;
  }
}
