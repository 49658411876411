.filter-head {
  background: var(--white);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
}

.department_filter {
  width: 200px;
}

.checkbox-wrapper-12 {
  position: relative;
  margin: 10px 0;
}

.checkbox-wrapper-12 > svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper-12 * {
  box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper-12 .cbx {
  width: auto;
  height: 24px;
  top: calc(50vh - 12px);
  left: calc(50vw - 12px);
}

.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: url("#goo-12");
  filter: url("#goo-12");
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked + label {
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
  40% {
    background: var(--red);
    box-shadow: 0 -18px 0 -8px var(--red), 16px -8px 0 -8px var(--red),
      16px 8px 0 -8px var(--red), 0 18px 0 -8px var(--red),
      -16px 8px 0 -8px var(--red), -16px -8px 0 -8px var(--red);
  }

  100% {
    background: var(--red);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: var(--red);
    box-shadow: 0 -18px 0 -8px var(--red), 16px -8px 0 -8px var(--red),
      16px 8px 0 -8px var(--red), 0 18px 0 -8px var(--red),
      -16px 8px 0 -8px var(--red), -16px -8px 0 -8px var(--red);
  }

  100% {
    background: var(--red);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: var(--red);
    box-shadow: 0 -18px 0 -8px var(--red), 16px -8px 0 -8px var(--red),
      16px 8px 0 -8px var(--red), 0 18px 0 -8px var(--red),
      -16px 8px 0 -8px var(--red), -16px -8px 0 -8px var(--red);
  }

  100% {
    background: var(--red);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: var(--red);
    box-shadow: 0 -18px 0 -8px var(--red), 16px -8px 0 -8px var(--red),
      16px 8px 0 -8px var(--red), 0 18px 0 -8px var(--red),
      -16px 8px 0 -8px var(--red), -16px -8px 0 -8px var(--red);
  }

  100% {
    background: var(--red);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

/* ----------- */
.check_label {
  padding-left: 30px;
  display: flex;
  align-items: center;
}
.save_permission {
  border: 1px solid var(--red);
  background: var(--red);
  padding: 10px 40px;
  color: var(--white);
  border-radius: 10px;
  transition: all 0.5s ease;
}
.save_permission:hover {
  background: var(--white);
  color: var(--red);
}
.permission_btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-button.collapsed:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-button.collapsed {
  color: var(--black) !important;
  background: #ffe1e1 !important;
}
.accordion-button {
  color: var(--white) !important;
  background: var(--red) !important;
}
.permission_body h2 {
  font-size: 14px;
  font-family: im;
}
.permission_body {
  background: var(--white);
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.add_icon_container {
  background-color: lightgray;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
