.chat_part {
  background: var(--white);
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.chat_list {
  border-right: 1px solid #e9e9e9;
  padding-right: 10px;
  height: 420px;
  overflow: auto;
}
.user_name > h6 {
  font-family: im;
  font-size: 14px;
  margin: 0;
}
.user_name > p {
  font-family: ir;
  font-size: 12px;
  margin: 0;
}
.search_list svg {
  position: absolute;
  top: 8px;
  right: 10px;
  fill: var(--gray);
}
.search_list {
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}
.img_name,
.chat_img_name {
  display: flex;
  align-items: center;
}
.img_name img {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 20px;
}
.profile_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}
.head_icons {
  display: flex;
}
.border_heading {
  border-bottom: 1px solid #e9e9e9;
}
.chat_tabs_btn {
  border-radius: 10px;
  border: 1px solid transparent;
  background: var(--white);
  color: var(--black);
  text-align: start;
  padding: 5px 10px;
  transition: all 0.3s ease;
  margin: 5px 0;
  font-size: 16px;
  font-family: im;
}
.chat_tabs_btn:hover,
.chat_tabs_btn.active {
  border-radius: 10px;
  background: #ffe1e1;
}
.chat_img_name img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.lets_start {
  color: #949494;
}
.date_number > p {
  font-size: 10px;
  font-family: ir;
  margin-bottom: 10px;
}
.chat_number {
  background: var(--red);
  border-radius: 50%;
  width: 21px;
  text-align: center;
}
.chat_number p {
  margin: 0;
  color: var(--white);
  font-size: 14px;
  font-family: ir;
}
.chat_date {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.chat_date p {
  border: 1px solid #e9e9e9;
  border-radius: 25px;
  margin: 0;
  padding: 5px 20px;
}
.chat_deliver p {
  font-size: 14px;
  font-family: ir;
  color: var(--black);
  background: #e2fff4;
  border-radius: 20px;
  padding: 10px;
}
.chat_deliver {
  display: flex;
}
.chat_send p {
  font-size: 14px;
  font-family: ir;
  color: var(--black);
  background: #ffe1e1;
  border-radius: 20px;
  padding: 10px;
}
.chat_send {
  display: flex;
  justify-content: end;
}
.user_chat {
  height: 380px;
  overflow-x: hidden;
  margin: 10px 0;
  padding-right: 10px;
}
.file_dropdown {
  position: absolute;
  z-index: 999;
  top: 3px;
  left: 5px;
  background: #d4d4d4;
  padding: 2px 7px 5px 7px;
  border-radius: 50%;
}
.message_field {
  padding-left: 40px;
  border-radius: 50px;
}
.doc_drop {
  font-size: 14px;
  font-family: im;
  padding: 10px 0;
  cursor: pointer;
}
.doc_drop > img {
  padding-right: 10px;
}
.buttons_tags {
  /* width: 100%; */
  background: transparent;
  border: transparent;
}
.view_img > img {
  width: 90px;
}
.view_img {
  width: 90px;
}
.eye_img {
  position: absolute;
  right: 10px;
}
.uper_line {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  padding: 10px;
}
.uper_line > p {
  font-size: 10px;
  font-family: ir;
  margin: 0;
  padding-left: 5px;
}
.image_view {
  height: 220px;
  overflow-x: hidden;
  padding-right: 10px;
}
.image_col {
  margin-top: 10px;
}
.nav_pils {
  flex-direction: column;
}
@media screen and (max-width: 1325px) {
  .image_col {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 991px) {
  .image_col {
    width: auto;
    margin-top: 10px;
  }
  .chat_part {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .nav_pils {
    flex-direction: row;
  }
}
@media screen and (max-width: 575px) {
  .image_view {
    height: 120px;
  }
  .nav_pils {
    flex-direction: column;
  }
  .chat_tabs_btn {
    width: 100%;
  }
  .chat_img_name {
    justify-content: space-evenly;
  }
}
