.board_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.dashboard_inner {
  padding: 30px 0;
}
.main_heading p {
  font-size: 16px;
  font-family: ir;
  color: var(--gray);
}
.role_div h4 {
  font-size: 16px;
  font-family: im;
  text-align: end;
  color: var(--black);
}
.role_div h5 {
  font-size: 16px;
  font-family: ib;
  color: var(--pink);
}
.in_card {
  background: var(--white);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
}
.in_card p {
  margin: 0;
  color: var(--gray);
  font-size: 12px;
  font-family: ir;
}
.in_card h6 {
  font-size: 16px;
  font-family: ib;
}
.c1 {
  color: var(--green);
}
.c2 {
  color: var(--orange);
}
.c3 {
  color: var(--blue);
}
.c4 {
  color: var(--red);
}
@media screen and (max-width: 767px) {
  .in_card {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 757px) {
  .board_header {
    display: block;
  }
  .role_div {
    display: flex;
  }
  .role_div h4 {
    padding-right: 5px;
  }
}
@media screen and (max-width: 555px) {
  .main_heading img {
    width: 100%;
  }
  .main_heading p {
    font-size: 14px;
  }
}
.graph_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.graph_part,
.task_card,
.priority_card,
.submit_before {
  background: var(--white);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.canvasjs-chart-canvas {
  width: 100%;
}
.canvasjs-chart-credit {
  display: none;
}
.graph_top h1,
.task_heading h1 {
  font-size: 20px;
  font-family: gb;
}
.task_heading {
  border-bottom: 1px solid #e9e9e9;
  align-items: center;
}
.task_details p {
  font-family: ir;
  font-size: 14px;
  color: var(--red);
  margin: 0;
}
.task_details,
.task_details_two {
  display: flex;
  justify-content: space-between;
}
.task_main {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0;
}
.red_dot {
  background: #ff0101;
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.by_name {
  border: 1px solid var(--gray);
  border-radius: 50px;
  padding: 5px 10px;
}
.name_date {
  display: flex;
  justify-content: space-between;
}
.name_date p {
  margin: 0;
  color: var(--gray);
  font-family: ir;
  font-size: 12px;
}
.orange_dot {
  background: var(--orange);
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.task_details_two p {
  font-family: ir;
  font-size: 14px;
  color: var(--black);
  margin: 0;
}
.view_all a {
  color: var(--gray);
  font-family: ir;
  font-size: 12px;
}
.view_all {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.priority_card {
  margin-top: 20px;
}
.priority_heading {
  justify-content: space-between;
  align-items: center;
}
.priority_heading h3 {
  font-size: 15px;
  font-family: ib;
  margin: 0;
}
.green_dot {
  background: var(--green);
  width: 16px;
  height: 16px;
  border-radius: 50px;
}
.dots {
  display: flex;
  align-items: center;
}
.colors_priority {
  display: flex;
  justify-content: space-between;
}
.dots p {
  margin: 0;
  padding-left: 5px;
}
.big_timer h1 {
  font-size: 20px;
  font-family: ib;
  margin: 0;
  text-align: center;
}
.big_timer {
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
}
.hurry p {
  font-size: 13px;
  font-family: im;
  color: var(--red);
  text-align: center;
  margin: 0;
}
.hurry {
  padding-top: 10px;
}

.request_btn {
  border: 1px solid var(--red) !important;
  padding: 10px 50px;
  border-radius: 6px !important;
  background: var(--red);
  color: var(--white);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
}
.request_btn:hover {
  background: var(--white);
  border: 1px solid var(--red);
  color: var(--black);
}
.request_heading h3 {
  font-size: 16px;
  font-family: ib;
  color: var(--black);
}
@media screen and (max-width: 1100px) {
  .submit_before {
    margin-top: 20px;
  }
  .graph_top {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .submit_before {
    margin-top: 20px;
  }
  .graph_top {
    display: flex;
  }
}
textarea:focus,
textarea:focus-visible {
  border-color: var(--green);
  outline: none;
}
.cancel_btn {
  background: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  padding: 10px 50px;
}
.cancel_btn:hover {
  background: var(--black);
  color: var(--white);
  border: 1px solid var(--black);
}
.modal-footer {
  justify-content: center;
}
.apply_btn {
  box-shadow: rgba(246, 53, 53, 0.2) 0px 7px 29px 0px;
  padding: 10px 50px;
}
.modal-title {
  font-size: 22px;
  font-family: ib;
}
