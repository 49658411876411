.dropdown_insight {
  position: absolute;
  top: 10px;
  right: 20px;
}

.insight_main .project_card_main {
  text-align: center;
}
.insight_main .pcard_header {
  justify-content: center;
}

.CircularProgressbar-path {
  stroke: var(--green) !important;
}
.CircularProgressbar-trail {
  stroke: transparent !important;
}
.CircularProgressbar-text {
  fill: var(--green) !important;
  font-size: 22px;
  font-family: gb;
}
.CircularProgressbar {
  border-radius: 50% !important;
  border: 15px solid #def2f2 !important;
}
.bar {
  border: 5px dashed #def2f2;
  border-radius: 50%;
  padding: 10px;
  background: var(--white);
  width: 200px;
}
.main_bar {
  margin-top: 10px;
}
.main_bar h5 {
  font-size: 15px;
  font-family: ib;
  text-align: center;
}
.main_bar p {
  font-size: 14px;
  font-family: ir;
  text-align: center;
  color: var(--gray);
}
.good {
  background: #bfffe6;
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
}

.remarks-text {
  margin: 0;
  color: var(--white);
  font-size: 14px;
  font-family: ib;
  text-align: center;
}

.good p {
  margin: 0;
  color: var(--black);
  font-size: 14px;
  font-family: ib;
  text-align: center;
}
.insight_right_main {
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
}
.header_row {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
}
.header_content p {
  font-size: 14px;
  font-family: im;
  /* text-align: center; */
  margin: 0;
}
/* .progresbar_content h6 {
    font-family: ib;
    font-size: 14px;
    color: var(--black);
  } */
/* .progress-bar {
    font-family: ir;
    font-size: 11px;
    color: var(--black);
  } */
.progress {
  height: auto;
}
.progress-bar {
  font-family: ir;
  font-size: 11px;
  color: var(--black);
  overflow: visible;
  padding: 20px 0 20px 0px;
  background: #ffc4c4;
}
.Progress_content .progress-bar {
  overflow: visible;
  padding: 5px 0 5px 0px;
  background: #ffe380;
}
.good_remarks,
.perfect_remarks,
.bad_remarks {
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
}

/* Add these styles to your stylesheet */

.remarks-poor {
  background-color: var(--red);
  padding: 10px;
  border-radius: 10px;
}

.remarks-below-average {
  background-color: #ffa07a;
  padding: 10px;
  border-radius: 10px;
}

.remarks-average {
  background-color: #ffff99;
  padding: 10px;
  border-radius: 10px;
}

.remarks-good {
  background-color: var(--green);
  padding: 10px;
  border-radius: 10px;
}

.good_remarks {
  background: #fff5d1;
}
.bad_remarks {
  background: #ffe1e1;
}
.perfect_remarks {
  background: #e1fff3;
}
.body_row {
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
}
.mobile_view_insight {
  display: none;
}
@media screen and (max-width: 575px) {
  .desktop_view_insight {
    display: none;
  }
  .mobile_view_insight {
    display: block;
  }
  .head_mobile {
    margin-top: 10px;
  }
  .task_tag {
    margin-top: 30px !important;
  }
  .progres_tag {
    margin-top: 50px !important;
  }
  .remark_tag {
    margin-top: 35px !important;
  }
  .insight_right_main {
    margin-top: 20px;
  }
  .colapse_class {
    height: 180px;
  }
  .updown_icon {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .mobile_view_insight {
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 0;
  }
}
@media screen and (max-width: 555px) {
  .head_mobile {
    margin-top: 30px;
  }
  .updown_icon {
    top: 18px;
  }
}
