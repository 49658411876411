.project_card_main {
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}
.no_del {
  background: var(--green);
  color: var(--white);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
  box-shadow: rgba(246, 53, 53, 0.2) 0px 7px 29px 0px;
  padding: 10px 50px;
}
.no_del:hover {
  background: var(--white);
  color: var(--green);
  border: 1px solid var(--green);
}
.realy_want h6 {
  font-size: 18px;
  font-family: ib;
  margin-left: 10px;
}
.project_img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.project_owner {
  display: flex;
  gap: 5px;
}

.name_company h6,
.card_discription h6 {
  font-family: ib;
  font-size: 20px;
}

.project_owner h6 {
  font-family: ib;
  font-size: 14px;
}
.name_company p,
.card_discription p {
  font-family: ir;
  color: var(--gray);
  font-size: 14px;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-toggle {
  padding: 0;
}
.drop_btn {
  display: flex;
  justify-content: end;
}
.dots_btn {
  background: none;
  border: none;
}

.pcard_header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 20px;
}
.owner_pic img {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 10px;
}
.owner_pic {
  display: flex;
}
.owner_pic p,
.date_time p {
  margin: 0;
  color: var(--gray);
  font-size: 14px;
  font-family: ir;
  margin-top: -2px;
}
.view_insight {
  background: var(--white);
  color: var(--red);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
  border: 1px solid var(--red);
}
.view_insight:hover {
  background: var(--red);
  color: var(--white);
}
.btn-primary {
  border-color: transparent;
}
.filter_part {
  display: flex;
  justify-content: space-between;
  background: var(--white);
  align-items: center;
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 20px;
}
.depart_filter {
  display: flex;
  align-items: center;
}
.select-dropdown {
  width: 200px;
}

.load_more {
  padding: 5px 40px;
}
@media screen and (max-width: 1200px) {
  .pcard_header {
    align-items: baseline;
  }
  .rs-picker,
  .select-dropdown,
  .search_input {
    width: 100% !important;
  }
  .search_field svg {
    top: 32px !important;
  }
  .depart_filter {
    margin: 10px 0;
  }
}
@media screen and (max-width: 1050px) {
  .pcard_header {
    align-items: baseline;
  }
}
@media screen and (max-width: 991px) {
  .project_img,
  .name_company {
    text-align: center;
  }
}

.assigned-members {
  display: flex;
  gap: 18px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid var(--red);
    object-fit: cover;
  }

  p {
    padding-top: 10px;
    padding-left: 10px;
  }
}

.initial-avatar {
  background-color: #ddd;
  color: #333;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.edit_status {
  display: flex;
  gap: 5px;
  align-items: center;
}

.todo_task_card:hover .hover-icons {
  display: flex;
}

.hover-icons {
  display: none;
  flex-direction: row;
  gap: 5px;
}

/* ---------------------- */
.mainlistdiv {
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 10px 20px;
  border-radius: 10px;
}
.projecttable_head {
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 10px;
}
.projectlist_main {
  height: 285px;
  overflow: scroll;
  overflow-x: hidden;
}
.projecttable_head {
  padding: 10px 20px;
  margin: 20px 0;
}
.project_row {
  padding: 10px 0;
  border-bottom: 1px solid #e9e9e9;
}
.projecttable_head p,
.project_row p {
  margin: 0;
  font-family: ir;
  font-size: 14px;
}
.project_row a {
  color: var(--black);
  text-decoration: underline;
}
.project_data:hover .edit_options {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.project_progress {
  display: flex;
  align-items: center;
}
.project_progress .progress {
  width: 100%;
  height: 10px;
}
.project_progress label {
  margin-left: 10px;
}
.down_btn a {
  color: var(--white) !important;
  text-decoration: none !important;
}
.add_project {
  display: flex;
  align-items: center;
  margin: 10px 0;
  background: #e9e9e9;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}
.add_project p {
  margin: 0;
  font-size: 14px;
  font-family: ir;
  padding-left: 5px;
}
@media screen and (max-width: 600px) {
  .filter_part,
  .filter_field {
    display: block !important;
  }
}
.project_date .rs-input-group-addon {
  display: none !important;
}
