.notfound_content {
  text-align: center;
}
.notfound_content > h3 {
  font-size: 20px;
  font-family: ib;
}
.notfound_content > p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
}
@media screen and (max-width: 991px) {
  .found_img > img {
    width: 100%;
  }
}
.depart_card {
  border: 2px solid var(--white);
  background: var(--white);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 20px;
}
.card_heading h3 {
  font-size: 20px;
  font-family: ib;
  color: var(--red);
}

.depart_tag > p {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
  margin: 0;
  text-align: center;
}
.depart_tag {
  border-radius: 5px;
  padding: 3px;
  background: #e9e9e9;
}
.Progress_content .progress-bar {
  background: #ffe380 !important;
}
.space_progress .progress-bar {
  background: var(--red) !important;
}
.user_progress .progress-bar {
  background: var(--green) !important;
}
.oneofthree > label {
  color: var(--black);
}
.oneofthree > label > span {
  color: var(--gray);
}
.view_insight {
  background: var(--white);
  color: var(--red);
  transition: all 0.3s ease;
  font-size: 16px;
  font-family: im;
  border: 1px solid var(--red);
}
.view_insight:hover {
  background: var(--red);
  color: var(--white);
}
.oneofthree {
  display: flex;
  justify-content: space-between;
}
.oneofthree > p {
  color: var(--gray);
  font-size: 14px;
  margin: 0;
}
.main_image p {
  font-size: 16px;
  font-family: ir;
  color: var(--gray);
}
@media screen and (max-width: 555px) {
  .main_image p {
    font-size: 14px;
  }
}
.check_bok {
  margin: 10px 0;
}

.model_heading p {
  margin: 0;
  color: var(--gray);
  font-size: 14px;
  font-family: ir;
}
.model_heading h1 {
  color: var(--red);
}

.checkbox_wrapper input[type="radio"] {
  display: none;
  visibility: hidden;
}

.checkbox_wrapper label {
  position: relative;
  padding-left: 70px;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  padding: 15px 0 15px 70px;
  border-radius: 10px;
  border: 1px solid #949494;
}

.checkbox_wrapper label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 19px !important ;
  left: 15px;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid var(--green);
  border-radius: 50%;
  z-index: 0;
}

.checkbox_wrapper input[type="radio"]:checked + label {
  padding-left: 70px;
  color: var(--white);
  background: var(--green);
  border: 1px solid var(--green);
}
.checkbox_wrapper input[type="radio"]:checked + label:before {
  background: var(--white);
  border-color: var(--white);
  border-radius: 50%;
}
.checkbox_wrapper label,
.checkbox_wrapper label::before {
  transition: 0.25s all ease;
}
.check_bok {
  margin: 10px 0;
}
.assign_radio {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 5px 0;
}
.date_col {
  margin-bottom: 50px;
}
.from_date {
  position: absolute;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
}
.from_date input {
  border: none;
}
input:focus-visible {
  outline: none !important;
}

.selected .depart_card {
  border: 2px solid var(--green);
}
