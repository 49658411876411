.profile_links {
  border: 2px solid transparent !important;
  border-radius: 0 !important;
  color: var(--gray);
  margin-right: 20px;
  font-size: 14px;
  font-family: im;
  padding: 10px 30px;
}
.profile_links:hover {
  border-bottom: 2px solid var(--green) !important;
  border-radius: 0 !important;
  color: var(--green);
  background: #e2fff4 !important;
}
.profile_links.active {
  border-bottom: 2px solid var(--green) !important;
  color: var(--green) !important;
  background: #e2fff4 !important;
  border-radius: 0 !important;
}
.border_line {
  border-bottom: 1px solid #e9e9e9;
}
.profile_detail {
  background: var(--white);
  border-radius: 10px;
  padding: 30px;
}
.picker {
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  margin: 0 auto;
  position: relative;
}
.inner_picker {
  background: #eff4f9;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
}
.image_picker > p {
  text-align: center;
  font-size: 16px;
  font-family: im;
  margin: 0;
}
.penlabel {
  padding: 10px;
  position: absolute;
  background: var(--green);
  border-radius: 10px;
  display: flex;
  color: var(--white);
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.sign_btn {
  display: flex;
  justify-content: end;
}
