/* @font-face {
  font-family: gb;
  src: url("./assets/fonts/Glory-ExtraBold.ttf");
  font-style: normal;
  font-display: swap;
} */
@font-face {
  font-family: gb;
  src: url("./assets/fonts/GILROY-EXTRABOLD.OTF");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: il;
  src: url("./assets/fonts/Inter-Light.otf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: ib;
  src: url("./assets/fonts/Inter-Bold.otf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: im;
  src: url("./assets/fonts/Inter-Medium.otf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: ir;
  src: url("./assets/fonts/Inter-Regular.otf");
  font-style: normal;
  font-display: swap;
}
:root {
  --mixgreen: linear-gradient(to right, #aeffdf, #0f2e22);
  --mixred: linear-gradient(to top, #ff8787, #ff6464);
  --red: #ff6464;
  --pink: #ff8787;
  --white: #fff;
  --gray: #949494;
  --black: #101010;
  --lgreen: #aeffdf;
  --green: #38ce94;
  --bcolor: #f6fbff;
  --blue: #0065ff;
  --orange: #f58a20;
}
a {
  text-decoration: none;
}
.form-control:focus {
  box-shadow: none;
  border-color: var(--green) !important;
}
/* -------- */
.main_header {
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.bell_num {
  background: var(--red);
  border-radius: 50%;
  width: 21px;
  position: absolute;
  top: -7px;
  right: -8px;
}
.bell_num p {
  margin: 0;
  padding-left: 2px;
  color: var(--white);
  font-size: 14px;
  font-family: ir;
}
.profile_side {
  display: flex;
}
.head_col {
  display: flex;
  align-items: center;
}
.role_heading h3 {
  font-size: 20px;
  font-family: im;
  margin: 0;
}
.role_heading span {
  font-family: ib;
  color: var(--red);
}
.profile_name img,
.profile_name_menu img {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 20px;
}
.profile_name,
.profile_name_menu {
  display: flex;
  align-items: center;
}
.profile_name p,
.profile_name_menu p {
  margin: 0;
  font-family: ib;
  font-size: 16px;
  color: var(--black);
}
.heading_col {
  display: flex;
  align-items: center;
}
.bell_icon {
  margin-right: 30px;
}
.profile_col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.togale {
  display: none;
}
.menu_btn {
  padding: 0;
}
@media screen and (max-width: 991px) {
  .lefti {
    display: none;
  }
  .heding_row {
    flex-direction: column-reverse;
  }
  .head_col,
  .profile_name {
    display: none;
  }
  .togale {
    display: block;
  }
  .profile_col {
    justify-content: end;
  }
  .main_motion {
    display: none;
  }
}
/* ----left side---- */
.nav-tabs {
  border: none;
  margin: 10px 0;
}
.menu_col {
  background: var(--white);
}
.side_link,
.icon_color {
  color: var(--gray) !important;
  display: flex;
  align-items: center;
  margin: 10px 0;
  border-radius: 10px !important;
  font-size: 14px;
  font-family: ir;
  border: 1px solid transparent !important;
}
.lefti {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: var(--white);
  min-height: 100vh;
}
.icon_color svg {
  margin: 0;
}
.side_link p,
.icon_color p {
  margin: 0;
  white-space: nowrap;
}
.side_link svg {
  margin-right: 10px;
}
.side_link:hover,
.active_menu {
  border-radius: 10px !important;
  border: 1px solid var(--green) !important;
  background: #e2fff4 !important;
  color: var(--green) !important;
}
.side_link:hover svg path,
.active_menu svg path {
  fill: var(--green);
}
.dropdown {
  list-style: none;
}
.main_motion {
  background-color: var(--white);
  overflow: hidden;
  padding: 0 10px;
  padding-top: 20px;
}
.icon_color:hover svg path {
  fill: var(--green);
}
.main_body {
  display: flex;
}
.toggle_button img {
  cursor: pointer;
}
.toggle_button {
  display: flex;
  justify-content: flex-end;
}
.notice_btn {
  border: none;
  padding: 0;
  background: none;
}
.drop_show.show {
  width: 350px;
}
.notice_name > p {
  margin: 0;
}
.notice_assign {
  color: var(--gray);
  font-size: 14px;
  font-family: ir;
}
.notice_time {
  color: var(--gray);
  font-size: 12px;
  font-family: ir;
}
.notice_user {
  font-size: 14px;
  font-family: im;
}
.notice_items {
  border-bottom: 1px solid #e9e9e9;
}
.notice_heading {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: var(--red);
  border-bottom: 1px solid #e9e9e9;
}
.notice_heading > h4 {
  font-size: 20px;
  font-family: ib;
}
.notice_heading > h5 {
  font-size: 16px;
  font-family: ib;
}
.main_heading > h3 {
  font-size: 30px;
  font-family: ib;
  background-image: linear-gradient(
    to right,
    #00a8ff,
    #38ce94,
    #38ce94,
    #00a8ff
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: var(--pink);
}

::-webkit-scrollbar:hover {
  background-color: var(--green);
}

::-webkit-scrollbar-thumb {
  background-color: var(--green);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--pink);
}
.back_login {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.back_login p {
  font-size: 14px;
  font-family: ir;
}
.back_login p span a {
  color: var(--red);
}
.check_info {
  color: var(--green);
}
