.tabs_data {
  background: var(--white);
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.plan_head {
  border-bottom: 1px solid #eff4f9;
}
.plan_head > h3 {
  color: var(--black);
  font-size: 20px;
  font-family: ib;
}
.upgrade {
  color: var(--red);
}
.pl_type {
  color: var(--gray) !important;
}
.plan_details > p {
  color: var(--black);
  font-size: 14px;
  font-family: ir;
}
.mainPl_detail {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.plan_subscription {
  margin-bottom: 20px;
}
.oneofthree {
  display: flex;
  justify-content: space-between;
}
.oneofthree > p {
  color: var(--gray);
  font-size: 14px;
  margin: 0;
}
.progress_col .Progress_content .progress-bar {
  padding: 5px 0 5px 0px;
  background: var(--green);
}

.upgrade_basic {
  background: var(--white);
  border: 1px solid var(--green);
  border-radius: 10px;
  padding: 30px 20px;
  height: 467px;
}
.card_price {
  text-align: center;
}
.card_price > h3 {
  font-size: 24px;
  font-family: ib;
}
.card_price > h4 {
  font-size: 42px;
  font-family: ib;
}
.card_price > h4 > span {
  font-size: 18px;
  font-family: ir;
}
.card_price > p {
  font-size: 12px;
  color: var(--gray);
  font-family: ir;
}
.buy_btn {
  background: var(--green);
  color: var(--white);
  transition: all 0.3s ease;
  /* padding: 0 20px; */
  width: 100%;
  font-size: 16px;
  font-family: im;
}
.buy_btn:hover {
  background: var(--white);
  border: 1px solid var(--green);
  color: var(--green);
}
.basic_list > ul {
  list-style: none;
}
.basic_list > ul > li > img {
  margin-right: 20px;
}
.basic_list > ul > li {
  font-size: 14px;
  font-family: ir;
  color: var(--gray);
  margin-bottom: 10px;
}
.upgrade_priemium {
  background: var(--white);
  border-radius: 10px;
  border: 2px solid var(--red);
  padding: 30px 20px;
}
.most_tag {
  background: #ffe1e1;
  border-radius: 10px;
  border: 1px solid var(--red);
  text-align: center;
  padding: 10px 20px;
  transform: translate(0, 23px);
}
.most_tag > h2 {
  font-size: 20px;
  font-family: ib;
  margin: 0;
}
.upgrade_card {
  padding-top: 45px;
}
.task_head {
  background: var(--white);
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.task_links {
  border: 1px solid transparent !important;
  border-radius: 0 !important;
  color: var(--black);
  margin-right: 20px;
  font-size: 14px;
  font-family: im;
  padding: 0;
}
.task_links:hover {
  border-bottom: 1px solid var(--red) !important;
  border-radius: 0 !important;
  color: var(--red);
  background: transparent !important;
}
.task_links.active {
  border-bottom: 1px solid var(--red) !important;
  color: var(--red) !important;
  border-radius: 0 !important;
}
@media screen and (max-width: 1118px) {
  .most_tag {
    padding: 5px;
  }
}
@media screen and (max-width: 757px) {
  .subs_col {
    width: 100%;
  }
}
